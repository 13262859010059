import { Controller } from "stimulus";
import Swiper from "swiper";

export default class extends Controller {
  connect = () => {
    // Init the GlideJS Slider
    this.slider = this.initSlider();
  };

  initSlider = () => {
    // GlideJS Params
    const slider = this.element.querySelector(".swiper-container");
    const options = {
      autoplay: {
        delay: 5000,
      },
      centeredSlides: true,
      centeredSlidesBounds: true,
      cssWidthAndHeight: true,
      loop: true,
      loopedSlides: 2,
      loopedAdditionalSlides: 2,
      slidesPerView: 1,
      breakpoints: {
        1200: {
          centeredSlides: false,
          slidesPerView: 2,
        },
      },
    };

    // If the slider exists, return glidejs.mount(). If not: false.
    return !!slider ? new Swiper(slider, options) : false;
  };
}
